<template>
  <div class="text-xs">
    <span>{{ $t(`${trPrefix}signInAgreement`) }}&nbsp;</span>

    <NuxtLink
      class="font-medium underline hover:no-underline cursor-pointer"
      target="_blank"
      :to="pplink"
      external
    >
      {{ $t(`${trPrefix}privacyPolicy`) }}
    </NuxtLink>

    <span>{{ $t(`${trPrefix}signInAgreementEnd`) }}</span>
  </div>
</template>

<script setup lang="ts">
const trPrefix = 'guestWorld.components.auth.login.UserAgreement.'

const pplink = computed(() => {
  return (
    useConfdata().navigation?.links.privacyPolicy?.href ||
    'https://www.interhome.group/privacy-policy'
  )
})
</script>

<style scoped></style>
